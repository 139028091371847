<template>
	<div class="form-box login-box2" v-loading="loading">
		<div style="width: 100%;height: 100%;display: flex; ">
			<div style="width: 58%;height: 85%;">
				&nbsp;
			</div>
			<div class="form-box-con">
			<img style="width: 21.9rem;height: 4.7rem;position: relative;right: 11.6rem;bottom: 2.5rem;" src="../../assets/img/dndx.png" alt="">
				<div class="data-form">
					
					<el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()">
						
						<div class="title">
							欢迎登录
						</div>
						<el-form-item prop="account">
							<el-input v-model="dataForm.account" placeholder="账户">
								<i slot="prefix"
								class="iconclass"
									>
									<img style="width:2.8rem;height:2.8rem" src="../../assets/img/user.png" alt />
								</i>
							</el-input>
						</el-form-item>
						<el-form-item prop="password">
							<el-input type="password" v-model="dataForm.password" placeholder="密码">
								<i slot="prefix"
								class="iconclass"
									>
									<img style="width:2.8rem;height:2.8rem"src="../../assets/img/pwd.png" alt />
								</i>
							</el-input>
						</el-form-item>
						<!-- <el-form-item prop="code" class="code-form-item">
			    <el-input
			      type="text"
			      v-model="dataForm.code"
			      placeholder="图形验证码"
			      style="width: 68%"
			    ></el-input>
			    <img class="code-img" ref="img" src @click="getCaptcha()" />
			  </el-form-item>-->
					</el-form>
					<div style="padding-top: 2rem">
						<el-button class="submit-btn" type="primary" @click="getCaptcha">登录</el-button>
					</div>
				</div>
			</div>
			
		</div>
		<div class="footer">©Copyright {{year}} 东南大学附属中大医院 All Rights Reserved</div>
	</div>
</template>

<script>
	import Qs from "qs";
	var regPhone = /^1[34578]\d{9}$/,
		regPwd = /^[a-z|A-Z|0-9|\-|_]{4,20}$/;
	import {
		getUUID
	} from "../../utils/index";
	import MD5 from "js-md5";
	export default {
		data() {
			/*var validatePhone = (rule, value, callback) => {
			    if (!value) {
			      callback(new Error('请输入手机号'));
			    } else if (!regPhone.test(value)) {
			      callback(new Error('手机号格式不正确'));
			    } else {
			      callback();
			    }
			  }*/
			var validatePwd = (rule, value, callback) => {
				if (!value) {
					callback(new Error("请输入密码"));
				}
				/*else if (!regPwd.test(value)){
				         callback(new Error('4-20位，字母、数字或_'));
				       }*/
				else {
					callback();
				}
			};
			return {
				usernameText: "", //用户名文本
				passwordText: "", //记住密码文本
				dataForm: {
					account: "",
					password: ""
					//codeImg: ''
				},
				dataRule: {
					account: [{
						required: true,
						message: "请输入用户名/手机号",
						trigger: "blur"
					}],
					password: [{
						required: true,
						validator: validatePwd,
						trigger: "blur"
					}],
					code: [{
						required: true,
						message: "请输入图形验证码",
						trigger: "blur"
					}]
				},
				userInfo: {},
				loading: false,
				uuid: "",
				year: "",
				appId: "2036675646",
				// doctorUnionId: "00ec8279761b4ea78697c182db4e7877",
				unionName: ""
			};
		},
		computed: {},
		mounted() {
			
			localStorage.currentTitle = '东南大学附属中大医院肾脏病诊治中心数据库与病例队列系统'
			document.title = '东南大学附属中大医院肾脏病诊治中心数据库与病例队列系统'
			let date = new Date()
			this.year = date.getFullYear()
		},
		methods: {
			// 获取滑块验证码
			getCaptcha() {
				let _this = this;
				_this.$refs["dataForm"].validate(valid => {
					if (valid) {
						//生成一个滑块验证码对象
						let captcha = new TencentCaptcha(_this.appId, function(res) {
							// 用户滑动结束或者关闭弹窗，腾讯返回的内容
							if (res.ret === 0) {
								let params = {
									randstr: res.randstr,
									ticket: res.ticket
								};
								_this
									.$http({
										url: _this.$http.adornUrl("/bind/describeCaptchaResult"),
										method: "post",
										data: Qs.stringify(params)
									})
									.then(({
										data
									}) => {
										let captchaCode = JSON.parse(data.data).CaptchaCode;
										if (data.data && captchaCode === 1) {
											_this.dataFormSubmit();
										} else {
											_this.$message.error("滑块校验未通过，请重新尝试");
										}
									});
							} else {
								// 提示用户完成验证
							}
						});
						// 滑块显示
						captcha.show();
					}
				});
			},
			// 提交表单
			dataFormSubmit() {
				// 判断复选框是否被勾选; 勾选则调用配置Cookie方法
				this.$refs["dataForm"].validate(valid => {
					if (valid) {
						let param = {
							account: this.dataForm.account,
							password: MD5(this.dataForm.password),
							// code: this.dataForm.code,
							// uuid: this.uuid,
							roleType: 1,
							unionId: this.doctorUnionId
						};
						localStorage.removeItem('token')
						this.$http({
							url: this.$http.adornUrl("/organBusiness/login"),
							method: "post",
							data: Qs.stringify(param)
						}).then(({
							data
						}) => {
							if (data.status) {
								localStorage.userInfo = JSON.stringify(data.data.user);
								localStorage.dept = JSON.stringify(data.data.dept);
								localStorage.setItem("token", data.data.token);
								this.$cookie.set('hospitalId', data.data.user.deptPid)

								this.getGzsInfo()

							} else {
								this.$message.error(data.msg);
							}
						});
					}
				});
			},
			// 获取团队下的病种
			getGzsInfo() {
				let that = this;
				that
					.$http({
						url: that.$http.adornUrl("/organBusiness/info"),
						method: "get"
					})
					.then(({
						data
					}) => {
						if (data.data) {
							if (data.data.teamsManages && data.data.teamsManages.length) {
								that.teamsManages = data.data.teamsManages;
								localStorage.teamsManages = JSON.stringify(that.teamsManages);
								if (data.data.lastTeamId!=null) {
													that.currentTeam =   that.teamsManages.find(item=>{
														   return item.id == data.data.lastTeamId
													   })
													    localStorage.currentTeam = that.currentTeam 
								  //that.currentTeam = JSON.parse(localStorage.currentTeam);
								} else {
								  that.currentTeam = data.data.teamsManages[0];
								  localStorage.currentTeam = JSON.stringify(data.data.teamsManages[0]);
								}
								this.showTeam = true
								this.gzsFlag = true

								localStorage.gzsFlag = true
								sessionStorage.loginType = 'zdLogin'
								this.$router.push({
									name: "home"
								});
							} else if (data.data.roleId == 'e89325119fa411e89e43525400557296') {
								console.log('没工作室+管理员身份');
								sessionStorage.loginType = 'zdLogin'
								this.$router.push({
									name: "home"
								});
							} else if (data.data.roleId != 'e89325119fa411e89e43525400557296') {

								localStorage.gzsFlag = false
								this.$message.error('您未加入任何工作室，无法访问')

							}
						}
					});
			},
			getUserInfo() {
				let that = this;
				that
					.$http({
						url: that.$http.adornUrl("/organBusiness/info"),
						method: "get"
					})
					.then(({
						data
					}) => {
						if (data.data) {
							if (data.data.avatar) {
								that.$store.commit("common/updateImageUrl", data.data.avatar);
							}
							if (data.data.teamsManages && data.data.teamsManages.length) {
								that.$store.commit(
									"common/updateTeamsManages",
									data.data.teamsManages
								);
								that.$store.commit(
									"common/updateCurrentTeam",
									data.data.teamsManages[0]
								);
								localStorage.teamsManages = JSON.stringify(
									data.data.teamsManages
								);
								localStorage.currentTeam = data.data.teamsManages[0];
							}
						}
					});
			}
		}
	};
</script>

<style lang="scss">
	.login-box2 {
		height: 100vh;
		background: url('../../assets/img/login2.png') center center no-repeat;
		background-size: 100% 100%;
		display: flex;

		.title {

			width: 100%;
			text-align: center;
			height: 4rem;
			font-size: 2.8rem;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #333333;
			line-height: 4rem;
			margin-top: 4rem;
			margin-bottom: 4rem;
		}
.iconclass{
	display: flex;align-items: center;margin-left: 0.8rem;margin-top: 1.6rem;
}
		.form-box-con {
			//position: absolute;
			width: 36%;
			margin-top: 18.3rem;
			height: 35.4rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			// width: 407px;
			// height: 354px;
			// left: 50%;
			// top: 40%;
			// margin-top: -170px;
			// margin-left: -203px;
		}

		.form-box-con-title {
			font-size: 3.2rem;
			font-weight: bold;
			color: #0099FF;
			margin-bottom: 2rem;
			text-align: center;
		}

		.data-form {
			//margin-left: 125%;
			width: 50.7rem;
			height: 100%;

			border-radius: 1rem;
			padding: 4rem 2rem;

			.data-form-title {
				text-align: left;
				padding-bottom: 2rem;
			}

			.submit-btn {
				height: 6rem;
				width: 100%;
	
			
				font-size: 2.8rem;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
		
				text-align: center;
			}

			.code-time {
				width: 12rem;
				text-align: center;
				color: #17b3a3;
			}
		}

		.back-login {
			font-size: 1.2rem;
			color: #17b3a3;
			text-align: right;
		}

		.code-form-item {
			// display: flex;
			// align-items: center;
		}

		.el-form-item {
			position: relative;

			.login-icon {
				position: absolute;
				width: 2rem;
				height: 2rem;
				display: inline-block;
				color: #999;
				font-size: 1.6rem;
				top: 0.7rem;
				left: 1rem;
				z-index: 10;
			}

			.el-input__inner {
				padding-left: 50px;
				background-color: #fff;
				height: 6rem;
			}

			.code-img {
				width: 9rem;
				height: 3.6rem;
				background: #ccc;
				vertical-align: middle;
				transform: scale(1.4);
				position: absolute;
				top: 0;
				right: 2rem;
			}
		}
	}

	.role-list {
		text-align: center;
		margin-bottom: 2rem;

		.el-radio__label {
			color: #fff;
		}
	}

	.router-group {
		overflow: hidden;
		padding-top: 1rem;

		a {
			color: #17b3a3;
		}
	}

	.footer {
		width: 100%;
		color: #fff;
		text-align: center;
		position: fixed;
		bottom: 2rem;
		left: 0;
	}
</style>
